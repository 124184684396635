<template>
  <div class="app-container">
    <div class="mytabel table-sm mr-0 ml-0 p-0">
      <div class="row table-sm mr-0 ml-0 p-0 mb-3 width-100">
        <div
          class="
            col-7
            p-0
            align-self-center
            font-weight-bold
            d-flex
            align-items-center
          "
        >
          <h5 class="d-inline mr-2 font-weight-bold">
            {{ $t("message.users") }}
          </h5>
          <crm-refresh @c-click="refresh()"></crm-refresh>
          <div class="text-center d-flex sorddata ml-3">
            <el-input
              size="mini"
              placeholder="Найти"
              prefix-icon="el-icon-search"
              v-model="filterForm.search"
              clearable
            ></el-input>
            <el-date-picker
              size="mini"
              v-model="filterForm.start_date"
              type="date"
              :format="'dd.MM.yyyy'"
              :value-format="'dd.MM.yyyy'"
              :placeholder="$t('message.start_date')"
            ></el-date-picker>
            <el-date-picker
              size="mini"
              v-model="filterForm.end_date"
              type="date"
              :format="'dd.MM.yyyy'"
              :value-format="'dd.MM.yyyy'"
              :placeholder="$t('message.end_date')"
            ></el-date-picker>
          </div>
        </div>
        <div
          class="
            col-5
            align-items-center align-self-center
            text-right
            pr-0
            d-flex
            justify-content-end
          "
        >
          <el-button
            size="mini"
            @click="drawerCreate = true"
            icon="el-icon-circle-plus-outline"
          >
            {{ $t("message.create") }}
          </el-button>
          <export-excel
            class="btn excel_btn"
            :data="list"
            :fields="excel_fields"
            @fetch="controlExcelData()"
            worksheet="Пользователи"
            name="Пользователи.xls"
          >
            <el-button size="mini" icon="el-icon-document-delete">
              Excel
            </el-button>
          </export-excel>
          <crm-column-settings
            :columns="columns"
            :modelName="'users'"
            @c-change="updateColumn"
          ></crm-column-settings>
        </div>
      </div>
      <table class="table table-bordered table-hover" v-loading="loadingData">
        <crm-pagination
          :pagination="pagination"
          @c-change="updatePagination"
        ></crm-pagination>
        <thead>
          <tr>
            <crm-th :column="columns.id" :sort="sort" @c-change="updateSort">{{
              columns.id.title
            }}</crm-th>
            <crm-th
              :column="columns.name"
              :sort="sort"
              @c-change="updateSort"
              >{{ columns.name.title }}</crm-th
            >
            <crm-th
              :column="columns.surname"
              :sort="sort"
              @c-change="updateSort"
              >{{ columns.surname.title }}</crm-th
            >
            <crm-th
              :column="columns.patronymic"
              :sort="sort"
              @c-change="updateSort"
              >{{ columns.patronymic.title }}</crm-th
            >
            <crm-th
              :column="columns.email"
              :sort="sort"
              @c-change="updateSort"
              >{{ columns.email.title }}</crm-th
            >
            <crm-th
              :column="columns.phone"
              :sort="sort"
              @c-change="updateSort"
              >{{ columns.phone.title }}</crm-th
            >
            <crm-th
              :column="columns.phone_two"
              :sort="sort"
              @c-change="updateSort"
              >{{ columns.phone_two.title }}</crm-th
            >
            <crm-th
              :column="columns.role_id"
              :sort="sort"
              @c-change="updateSort"
              >{{ columns.role_id.title }}</crm-th
            >
            <crm-th
              :column="columns.role_name"
              :sort="sort"
              @c-change="updateSort"
              >{{ columns.role_name.title }}</crm-th
            >
            <crm-th
              :column="columns.gender"
              :sort="sort"
              @c-change="updateSort"
              >{{ columns.gender.title }}</crm-th
            >
            <crm-th
              :column="columns.id_code"
              :sort="sort"
              @c-change="updateSort"
              >{{ columns.id_code.title }}</crm-th
            >
            <crm-th
              :column="columns.locked"
              :sort="sort"
              @c-change="updateSort"
              >{{ columns.locked.title }}</crm-th
            >
            <crm-th
              :column="columns.last_login"
              :sort="sort"
              @c-change="updateSort"
              >{{ columns.last_login.title }}</crm-th
            >
            <crm-th
              :column="columns.created_at"
              :sort="sort"
              @c-change="updateSort"
              >{{ columns.created_at.title }}</crm-th
            >
            <crm-th
              :column="columns.updated_at"
              :sort="sort"
              @c-change="updateSort"
              >{{ columns.updated_at.title }}</crm-th
            >
            <crm-th
              :column="columns.settings"
              :sort="sort"
              @c-change="updateSort"
              >{{ columns.settings.title }}</crm-th
            >
          </tr>
          <tr>
            <th v-if="columns.id.show">
              <el-input
                clearable
                size="mini"
                v-model="filterForm.id"
                class="id_input"
                :placeholder="columns.id.title"
              ></el-input>
            </th>
            <th v-if="columns.name.show">
              <el-input
                clearable
                size="mini"
                v-model="filterForm.name"
                :placeholder="columns.name.title"
              >
              </el-input>
            </th>
            <th v-if="columns.surname.show">
              <el-input
                clearable
                size="mini"
                v-model="filterForm.surname"
                :placeholder="columns.surname.title"
              >
              </el-input>
            </th>

            <th v-if="columns.patronymic.show">
              <el-input
                clearable
                size="mini"
                v-model="filterForm.patronymic"
                :placeholder="columns.patronymic.title"
              >
              </el-input>
            </th>
            <th v-if="columns.email.show">
              <el-input
                clearable
                size="mini"
                v-model="filterForm.email"
                :placeholder="columns.email.title"
              >
              </el-input>
            </th>
            <th v-if="columns.phone.show">
              <el-input
                clearable
                size="mini"
                v-model="filterForm.phone"
                :placeholder="columns.phone.title"
              >
              </el-input>
            </th>
            <th v-if="columns.phone_two.show">
              <el-input
                clearable
                size="mini"
                v-model="filterForm.phone_two"
                :placeholder="columns.phone_two.title"
              >
              </el-input>
            </th>
            <th v-if="columns.role_id.show">
              <el-input
                clearable
                size="mini"
                v-model="filterForm.role_id"
                :placeholder="columns.role_id.title"
              >
              </el-input>
            </th>
            <th v-if="columns.role_name.show">
              <!-- <el-input clearable size="mini" :placeholder="columns.role_name.title">
                            </el-input> -->
            </th>
            <th v-if="columns.gender.show">
              <el-input
                clearable
                size="mini"
                v-model="filterForm.gender"
                :placeholder="columns.gender.title"
              >
              </el-input>
            </th>
            <th v-if="columns.id_code.show">
              <el-input
                clearable
                size="mini"
                v-model="filterForm.id_code"
                :placeholder="columns.id_code.title"
              >
              </el-input>
            </th>
            <th v-if="columns.locked.show">
              <el-input
                clearable
                size="mini"
                v-model="filterForm.locked"
                :placeholder="columns.locked.title"
              >
              </el-input>
            </th>
            <th v-if="columns.last_login.show">
              <el-input
                clearable
                size="mini"
                v-model="filterForm.last_login"
                :placeholder="columns.last_login.title"
              >
              </el-input>
            </th>

            <th v-if="columns.created_at.show">
              <el-date-picker
                :format="'dd.MM.yyyy'"
                :value-format="'dd.MM.yyyy'"
                :placeholder="columns.created_at.title"
                v-model="filterForm.created_at"
                size="mini"
              >
              </el-date-picker>
            </th>
            <th v-if="columns.updated_at.show">
              <el-date-picker
                :format="'dd.MM.yyyy'"
                :value-format="'dd.MM.yyyy'"
                :placeholder="columns.updated_at.title"
                v-model="filterForm.updated_at"
                size="mini"
              >
              </el-date-picker>
            </th>
            <th v-if="columns.settings.show"></th>
          </tr>
        </thead>

        <transition-group name="flip-list" tag="tbody">
          <tr v-for="user in users" :key="user.id" class="cursor-pointer">
            <td v-if="columns.id.show">{{ user.id }}</td>
            <td v-if="columns.name.show">{{ user.name }}</td>
            <td v-if="columns.surname.show">{{ user.surname }}</td>
            <td v-if="columns.patronymic.show">{{ user.patronymic }}</td>
            <td v-if="columns.email.show">{{ user.email }}</td>
            <td v-if="columns.phone.show">{{ user.phone }}</td>
            <td v-if="columns.phone_two.show">{{ user.phone_two }}</td>
            <td v-if="columns.role_id.show">{{ user.role_id }}</td>
            <td v-if="columns.role_name.show">{{ user.role_name }}</td>
            <td v-if="columns.gender.show">{{ user.gender }}</td>
            <td v-if="columns.id_code.show">{{ user.id_code }}</td>
            <td v-if="columns.locked.show">{{ user.locked }}</td>
            <td v-if="columns.last_login.show">{{ user.last_login }}</td>
            <td v-if="columns.created_at.show">
              {{ user.created_at }}
            </td>
            <td v-if="columns.updated_at.show">
              {{ user.updated_at }}
            </td>
            <td v-if="columns.settings.show" class="settings-td">
              <crm-setting-dropdown
                name="user"
                :model="user"
                :actions="actions"
                @edit="edit"
                @delete="destroyModel"
              ></crm-setting-dropdown>
            </td>
          </tr>
        </transition-group>
      </table>

      <!-- Creating User model  -->
      <el-drawer
        class="popups_title"
        :visible.sync="drawerCreate"
        @open="reopenUpdate = true"
        @close="reloadIfChanged('reopenUpdate')"
        size="80%"
      >
        <AddUser @c-close="closeDrawer"></AddUser>
      </el-drawer>
      <!-- Creating User model  -->
      <!-- Updating User model  -->
      <el-drawer
        size="80%"
        :visible.sync="drawer.update.status"
        :ref="drawer.update.name"
        @opened="drawerOpened(drawer.update.component)"
        @closed="drawerClosed(drawer.update.component)"
      >
        <UpdateUser
          :selected="selectedModel"
          :ref="drawer.update.component"
          :drawer-name="drawer.update.name"
        >
        </UpdateUser>
      </el-drawer>
      <!-- Updating User model  -->
    </div>
  </div>
</template>

<script>
import AddUser from "./create";
import UpdateUser from "./update";
import { mapGetters, mapActions, mapState } from "vuex";
import list from "@/utils/mixins/list";
import drawer from "@/utils/mixins/drawer";
export default {
  mixins: [list, drawer],
  data() {
    return {
      json_fields: {
        "№": name,
        Наименование: "",
        Имя: "",
        Телефон: "",
        Создан: "",
      },
      loadingData: false,
      drawerCreate: false,
      drawerUpdate: false,
      users: [],
      selectedModel: {},
      excel_fields: {},
        drawer: {
        create: {
          name: "create",
          status: false,
          component: "componentDrawerCreate",
        },
        update: {
          name: "update",
          status: false,
          component: "componentDrawerUpdate",
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      roles: "roles/list",
      list: "users/list",
      columns: "users/columns",
      pagination: "users/pagination",
      statues: "users/statues",
      filter: "users/filter",
      sort: "users/sort",
    }),
    actions: function () {
      return ["edit", "delete"];
    },
  },
  async mounted() {
    this.controlExcelData();
  },
  watch: {
    columns: {
      handler: function () {
        this.controlExcelData();
      },
      deep: true,
    },
  },
  async created() {},

  methods: {
    ...mapActions({
      loadRoles: "roles/index",
      updateList: "users/index",
      setSearch: "users/setSearch",
      updatePagination: "users/updatePagination",
      updateSort: "users/updateSort",
      updateFilter: "users/updateFilter",
      updateColumn: "users/updateColumn",
      editModel: "users/show",
      delete: "users/destroy",
      refreshData: "users/refreshData",
    }),
    fetchData() {
      this.loadingData = true;
      const query = {
        ...this.filter,
        ...this.pagination,
        ...this.sort,
      };

      this.updateList(query)
        .then((res) => {
          this.users = res.users;
          this.loadingData = false;
        })
        .catch((err) => {
          this.loadingData = false;
        });
    },
    handleCurrentChange(val) {
      this.updatePagination({
        key: "page",
        value: val,
      })
        .then((res) => {})
        .catch((err) => {});
    },
    controlExcelData() {
      this.excel_fields = {};
      for (let key in this.columns) {
        if (this.columns.hasOwnProperty(key)) {
          let column = this.columns[key];
          if (column.show && column.column !== "settings") {
            this.excel_fields[column.title] = column.column;
          }
        }
      }
    },
    refresh() {
      this.refreshData()
        .then((res) => {
          this.filterForm = JSON.parse(JSON.stringify(this.filter));
        })
        .catch((err) => {});
    },
    async edit(model) {
      this.$loadingCursor("wait");
      await this.editModel(model.id)
        .then(async (res) => {
          this.$loadingCursor("default");
          this.selectedModel = model;
          this.drawer.update.status = true;
        })
        .catch((err) => {
          this.$loadingCursor("default");
        });
    },
    destroyModel(model) {
      this.delete(model.id)
        .then((res) => {
          this.$alert(res);
          this.fetchData();
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  components: {
    AddUser,
    UpdateUser,
  },
};
</script>
